import { useQuery } from "react-query";

import { CalculationsEngineApi } from "../../..";

import { calculationResultByIdQueryKey } from "../query-cache";

export const useGetCalculationHistoryById = (calculationId: string) => {
  const queryKey = calculationResultByIdQueryKey(calculationId);

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryKey,
    () => CalculationsEngineApi.Calculations.getCalculationHistoryById(calculationId),
    {
      enabled: !!calculationId,
      refetchOnMount: "always"
    }
  );

  return {
    results: data?.data,
    error,
    isFetching,
    loading: isLoading,
    refetch
  };
};
