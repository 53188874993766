import { useQuery } from "react-query";

import { CalculationsEngineApi } from "../../..";

import { calculationsByLocationId } from "../query-cache";
import { CalculationsListResponse } from "../../../types/calculation-engine";

export const useGetCalculationsByLocationId = ({ locationId }: { locationId: string }) => {
  const queryKey = calculationsByLocationId(locationId);

  const { isLoading, isSuccess, isFetching, isError, data, error, refetch } =
    useQuery<CalculationsListResponse>(
      queryKey,
      () => CalculationsEngineApi.Calculations.getCalculationsByLocationId(locationId),
      { enabled: !!locationId, refetchOnMount: "always" }
    );

  return {
    isLoading,
    isSuccess,
    isFetching,
    isError,
    calculations: data?.data,
    error,
    refetch
  };
};
